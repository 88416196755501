import * as React from 'react';
import { useParams } from 'react-router-dom';
import FeaturedPost from './post/components/FeaturedPost';
import PostsSlider from './post/components/PostsSlider';
import shapes from './shapes.json';
import Animation from './shared/components/UIElements/Animation';


const Home = props => {
  const myRef = React.useRef(null)
  const postId = useParams().pid;
    
    let featuredPost = props.items.find(p => p.id === postId);
    if(!featuredPost){
      featuredPost = props.items[0]
    }
  const filteredPosts = props.items.filter(p => p.id !== featuredPost.id);
    
    
     

   
   
  
  return<>
  
        <div ref={myRef} className="posts-wrapper">
        {featuredPost && <FeaturedPost item={featuredPost} />}
        </div>
        <div className="posts-wrapper">
          <div className="info-container">
            <div><Animation speed={0.2} data={shapes} /></div>
            <div>
              <h2>Did you know?</h2>
              <p>The rise of AI has seen a corresponding increase in the use of AI to write blog posts. While this can be seen as a positive development – after all, AI can help to improve the quality of writing and make it more engaging – there is a darker side to this trend.</p>
              <p>As AI increasingly relies on big data to learn and improve its writing, there is a risk that companies and individuals will use this data to manipulate people. For example, imagine a company that sells fitness products. They could use AI to study the blog posts of people who are interested in health and fitness, and then use this data to target these individuals with ads for their products.</p>

              <p>While there is nothing inherently wrong with this, it does raise some ethical concerns. Firstly, it is important to consider the consent of the people who are being targeted. Secondly, there is a risk that this kind of AI-based marketing could be used to exploit people's insecurities. For example, a person who is struggling to lose weight may be targeted with ads for products that promise quick and easy results.</p>
              <p>It is important to be aware of these risks when using AI to write blog posts. While AI can be a powerful tool, it is important to use it responsibly and to respect the privacy of the people who are being targeted.</p>

              </div>
          </div>
        </div>
        <div className="posts-wrapper">
        {props.items && <PostsSlider postRef={myRef} items={filteredPosts} />}
        </div>
      
      </>
}



export default Home