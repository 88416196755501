import * as React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import {useHttpClient} from './shared/hooks/http-hook';
import ErrorModal from './shared/components/UIElements/ErrorModal';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';
import Animation from './shared/components/UIElements/Animation';
import Cookies from 'universal-cookie';
import scroll from './scroll.json';
import Modal from './shared/components/UIElements/Modal';
import Button from './shared/components/FormElements/Button';
import Footer from './shared/components/UIElements/Footer';
import Home from './Home';
import { CSSTransition } from 'react-transition-group';
import AdBanner from './shared/components/UIElements/AdBanner';
const scrollToRef = (ref) => window.scrollTo({
  left:0,
  top:ref.current.offsetTop - 50,
  behavior:"smooth"
}) 

function App() {
  const {isLoading, clearError, error, sendRequest} = useHttpClient();
  const [isCookies, setIsCookies] = React.useState();
  const [isMobAds, setMobileAds] = React.useState();
  const [isDeskAds, setDesktopAds] = React.useState();
  
 
const acceptCookies = () => {
  const cookies = new Cookies();
  cookies.set('MIO-WEB', {accept: true}, { path: '/' });
  setIsCookies(true);
}

React.useEffect(()=>{
  window.scrollTo(0,0);

const getCookies = () => {
  const cookies = new Cookies();
  let myCookies = cookies.get('MIO-WEB');
  if(!myCookies){
    setIsCookies(false)
  }else{
    setIsCookies(true)
  }
  

}

getCookies()
},[])

  const [loadedPosts, setLoadedPosts] = React.useState();
  const myRef = React.useRef(null)

  const [text, setText] = React.useState("")
  const [fullText, setFullText] = React.useState(
    "Discover a Blog."
    )
    const [index, setIndex] = React.useState(0)
    
React.useEffect(()=>{
    if (index < fullText.length) {
      setTimeout(() => {
        setText(text + fullText[index])
        setIndex(index + 1)
      }, 180)
    }
  },[index, text,fullText]); 

  React.useEffect(()=>{
  
    const fetchData = async () => {
      

        try{
            const responsePosts = await sendRequest('https://blogai.miomideal.com/api/posts');
            const filteredPosts = responsePosts.posts.filter(p => p.category.title === "Published").reverse();
            const mobileAds = responsePosts.posts.sort(() => 0.5 - Math.random() ).filter(p => p.category.title === "Ad | Mobile");
            const desktopAds = responsePosts.posts.sort(() => 0.5 - Math.random() ).filter(p => p.category.title === "Ad | Desktop");
            
          
            
            setLoadedPosts(filteredPosts);
            setMobileAds(mobileAds);
            setDesktopAds(desktopAds);
        }catch(err){
                console.log(err)
        }
    };
    fetchData();
},[sendRequest]); 

const executeScroll = () => {
  scrollToRef(myRef)
}


  return (<React.Fragment>
    <ErrorModal error={error} onClear={clearError} />
    {isLoading && <LoadingSpinner asOverlay />}
    <Modal
      show={!isCookies}
            header="🍪 Cookies are the best!"
            
            footerClass="cookies__modal-actions"
            footer={
                <React.Fragment>
                    <Button inverse onClick={acceptCookies}>Consent required Cookies</Button>
                    <Button onClick={acceptCookies}>Consent Cookies</Button>
                </React.Fragment>
            }
        >   
        
        <p style={{color: 'white'}}>
        Welcome to our website! We use cookies to ensure that we give you the best experience on our website. By continuing to browse the site, you are agreeing to our use of cookies. <br /> 
        <a href="https://www.miomideal.com/datenschutz" target="_blank" rel="noreferrer" >Cookies policy</a>
        </p>
        </Modal>
   {isCookies && !isLoading && <div className="App">
      <header id="HEAD" className="App-header" onClick={executeScroll}>
      <CSSTransition
                    in={!isLoading}
                    timeout={2000}
                    classNames="fade"
                    unmountOnExit
                >
      
        <img src={logo} width={150} alt="logo" />
        </CSSTransition>
        <h2 style={{color:"#FF2E4C"}}>
        {text}
        </h2>
        <CSSTransition
                    in={text.length === fullText.length}
                    timeout={3000}
                    classNames="slide"
                    unmountOnExit
                >
                   <p>Written by AI.</p>
                </CSSTransition>
                <CSSTransition
                    in={text.length === fullText.length}
                    timeout={3000}
                    classNames="slide"
                    unmountOnExit
                >
        <Animation data={scroll} height={280} />

                </CSSTransition>
      </header>
      <HashRouter>
      <Routes>
            <Route path="/" element={<Home items={loadedPosts}/>} />
            <Route path="/:pid" element={<Home items={loadedPosts}/>} />
        </Routes>      
      </HashRouter>

     <Footer />
    </div>}
    {isCookies && loadedPosts && <AdBanner mobileAds={isMobAds} desktopAds={isDeskAds} />}
    </React.Fragment>
  );
}

export default App;
